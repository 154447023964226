exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-archive-tsx": () => import("./../../../src/templates/blog-archive.tsx" /* webpackChunkName: "component---src-templates-blog-archive-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-jamstack-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/jamstack/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-jamstack-index-mdx" */),
  "component---src-templates-case-studies-archive-tsx": () => import("./../../../src/templates/case-studies-archive.tsx" /* webpackChunkName: "component---src-templates-case-studies-archive-tsx" */),
  "component---src-templates-case-study-tsx-content-file-path-content-case-studies-case-study-andrew-ng-personal-website-index-mdx": () => import("./../../../src/templates/case-study.tsx?__contentFilePath=/opt/build/repo/content/case-studies/case-study-andrew-ng-personal-website/index.mdx" /* webpackChunkName: "component---src-templates-case-study-tsx-content-file-path-content-case-studies-case-study-andrew-ng-personal-website-index-mdx" */),
  "component---src-templates-case-study-tsx-content-file-path-content-case-studies-case-study-data-centric-ai-website-index-mdx": () => import("./../../../src/templates/case-study.tsx?__contentFilePath=/opt/build/repo/content/case-studies/case-study-data-centric-ai-website/index.mdx" /* webpackChunkName: "component---src-templates-case-study-tsx-content-file-path-content-case-studies-case-study-data-centric-ai-website-index-mdx" */),
  "component---src-templates-case-study-tsx-content-file-path-content-case-studies-case-study-deeplearning-ai-migration-to-jamstack-index-mdx": () => import("./../../../src/templates/case-study.tsx?__contentFilePath=/opt/build/repo/content/case-studies/case-study-deeplearning-ai-migration-to-jamstack/index.mdx" /* webpackChunkName: "component---src-templates-case-study-tsx-content-file-path-content-case-studies-case-study-deeplearning-ai-migration-to-jamstack-index-mdx" */),
  "component---src-templates-storyblok-story-tsx": () => import("./../../../src/templates/storyblok-story.tsx" /* webpackChunkName: "component---src-templates-storyblok-story-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

